:root {
  /* Light mode colors */
  --primary-color-light: #17DAFD;
  --secondary-color-light: #6590D5;
  --background-color-light: rgba(255, 255, 255, 0.35);
  --text-color-light: #000000;
  --placeholder-color-light: #68dbf2;
  --error-color-light: #fb6c6c;
  --error-bg-light: #ffe5e5;

  /* Dark mode colors */
  --primary-color-dark: #00A3D9;
  --secondary-color-dark: #3A6BC5;
  --background-color-dark: rgba(255, 255, 255, 0.35);
  --text-color-dark: #000000;
  --placeholder-color-dark: #4DB5D0;
  --error-color-dark: #FF8080;
  --error-bg-dark: #ffe5e5;

  /* Default to light mode */
  --primary-color: var(--primary-color-light);
  --secondary-color: var(--secondary-color-light);
  --background-color: var(--background-color-light);
  --text-color: var(--text-color-light);
  --placeholder-color: var(--placeholder-color-light);
  --error-color: var(--error-color-light);
  --error-bg: var(--error-bg-light);

  /* Fonts */
  --font-family: "Poppins", sans-serif;

  /* Sizes */
  --border-radius: 30px;

  /* Effects */
  --blur-effect: blur(15px);
  --blur-effect-background-color: #a0a0a015;

  /* Safe Area Insets */
  --sat: env(safe-area-inset-top, 0);
  --sar: env(safe-area-inset-right, 0);
  --sab: env(safe-area-inset-bottom, 0);
  --sal: env(safe-area-inset-left, 0);
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: var(--primary-color-dark);
    --secondary-color: var(--secondary-color-dark);
    --background-color: var(--background-color-dark);
    --text-color: var(--text-color-dark);
    --placeholder-color: var(--placeholder-color-dark);
    --error-color: var(--error-color-dark);
    --error-bg: var(--error-bg-dark);
  }
}

/* Dark mode class for manual toggling */
.dark-mode {
  --primary-color: var(--primary-color-dark);
  --secondary-color: var(--secondary-color-dark);
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --placeholder-color: var(--placeholder-color-dark);
  --error-color: var(--error-color-dark);
  --error-bg: var(--error-bg-dark);
}

body {
  font-family: var(--font-family);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  color: var(--text-color);
  transition: color 0.3s ease, background-color 0.3s ease;
}

/* Common styles */
.hidden {
  opacity: 0;
  visibility: hidden;
}

.visible {
  opacity: 1;
  visibility: visible;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

/* Scrollbar styles */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}

/* Utility classes for text colors */
.text-primary { color: var(--primary-color); }
.text-secondary { color: var(--secondary-color); }
.text-error { color: var(--error-color); }

/* Utility classes for background colors */
.bg-primary { background-color: var(--primary-color); }
.bg-secondary { background-color: var(--secondary-color); }
.bg-error { background-color: var(--error-bg); }