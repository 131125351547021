.chat-header {
    padding: 1px 10px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    position: absolute;
    top: var(--sat);
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 2;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  
  .company-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    overflow: hidden;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  }
  
  .company-logo {
    height: 40px;
    width: 40px;
    display: block;
    margin: 0 auto;
  }
  
  .chat-close-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }
  
  .chat-close-button img {
    width: 30px;
    height: 30px;
  }
  
  .chat-close-button:hover {
    transform: scale(1.1);
  }
  
  .chat-close-button:active {
    transform: scale(0.9);
  }
  
  .chat-header-title {
    font-size: 24.5px;
    margin-left: 1px;
    font-family: var(--font-family);
    background-color: transparent;
    color: #009DD7;
    font-weight: 600;
    letter-spacing: -0.05em;
  }