.chatbot-widget-container {
    position: fixed;
    inset: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    color: white;
    -webkit-overflow-scrolling: touch;
  }
  
  .chat-window {
    position: relative;
    height: 100%;
    width: 100%;
    box-shadow: 0 0 0 0.25px var(--primary-color);
    background-color: var(--background-color);
    backdrop-filter: var(--blur-effect);
    -webkit-backdrop-filter: var(--blur-effect);
    transform: translate3d(0, 0, 0);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.2, 0, 0.3, 0.2), opacity 0.3s ease;
    z-index: 999;
    overflow: hidden;
  }
  
  .chat-window.open {
    transform: translateY(0);
    opacity: 1;
  }
  
  .messages-list {
    flex-grow: 1;
    overflow-y: auto;
    list-style: none;
    padding: 6px;
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    position: relative;
    transition: padding-bottom 0.3s ease;
    gap: 1px;
  }
  
  .messages-list-content {
    display: flex;
    flex-direction: column;
    min-height: min-content;
  }
  
  .scroll-to-bottom-button {
    position: fixed;
    width: 40px;
    height: 40px;
    padding-bottom: 5px;
    bottom: calc(var(--sab) + 5px);
    z-index: 3000;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    left: 45%;
    right: 50%;
  }

  .scroll-to-bottom-button.hidden {
    display: none;
  }
  
  .scroll-to-bottom-button:hover {
    transform: scale(1.1);
  }
  
  .scroll-to-bottom-button:active {
    transform: scale(0.9);
  }
  
  @media (max-width: 768px) {
    .chatbot-widget-container {
      padding: 2px;
    }
  
    .chat-window {
      border-radius: var(--border-radius);
    }
  }