.animated-content {
  display: inline-block;
  word-break: break-word;
  line-height: 1.3;
  font-size: 14px !important;
}

.animated-content.stream .typing-cursor {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #17DAFD;
  margin-left: 4px;
  animation: pulse 3.5s infinite;
  white-space: pre-wrap;
}

.animated-content.stream .typing-cursor.solid {
  animation: none;
  background-color: #17DAFD;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1.7);
  }
  50% {
    transform: scale(0.4);
  }
}

.animated-content.slide {
  animation: slide-in 0.5s ease-out;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
