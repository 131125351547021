.message {
  display: inline-block;
  padding: 1px;
  position: relative;
  word-break: break-word;
  font-size: 14px;
  margin-bottom: 5px;
  max-width: 90%;
  vertical-align: middle;
  line-height: 1.2;
  animation: subtle-bounce 600ms linear both;
}

.message-text {
  display: inline-block;
  vertical-align: middle;
  padding: 1px;
}

.text-background {
  border-radius: var(--border-radius);
  padding: 1px 15px;
  display: inline-block;
  word-break: break-word;
  line-height: 1.3;
  font-size: 15px;
}

.text-background.user {
  background-color: var(--primary-color);
}

.text-background.assistant {
  background-color: var(--secondary-color);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.message.user {
  align-self: flex-end;
  color: white;
  animation: bounce 800ms linear both;
}

.message.failed {
  background-color: var(--error-bg);
}

.message.failed .message-text {
  color: var(--error-color);
}

.retry-button {
  margin-left: auto;
  background-color: var(--error-color);
  color: white;
  border: none;
  padding: 1px 5px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
}

.typing-cursor {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary-color);
  margin-left: 4px;
  animation: pulse 3.5s infinite;
}

@keyframes pulse {
  0%, 100% { transform: scale(1.7); }
  50% { transform: scale(0.4); }
}

@keyframes subtle-bounce {
  0% { transform: translateY(0); }
  20% { transform: translateY(-5px); }
  40% { transform: translateY(2px); }
  60% { transform: translateY(-2px); }
  80% { transform: translateY(1px); }
  100% { transform: translateY(0); }
}

@keyframes bounce {
  0% { transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% { transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}