.message-input-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  padding-bottom: calc(10px + var(--sab));
  min-height: 58px;
}

.message-input {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  transition: width 0.5s ease;
  border: 2px solid var(--primary-color);
  margin: 0px 6px 0px 6px;
  width: -webkit-fill-available;
  border-radius: var(--border-radius);
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: var(--blur-effect);
  -webkit-backdrop-filter: var(--blur-effect);
  transform: translate3d(0, 0, 0);
  color: var(--text-color);
  overflow: hidden;
}

.message-input .placeholder {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 500;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  line-height: 54px;
  transition: width 0.5s ease, opacity 0.5s ease;
}

.message-input .placeholder.show {
  animation: animatePlaceholder 2s linear forwards;
}

.message-input .placeholder.hide {
  animation: animatePlaceholderReverse 0.5s linear forwards;
}

@keyframes animatePlaceholder {
  to {
    width: calc(100% - 30px);
    opacity: 0.6;
  }
}

@keyframes animatePlaceholderReverse {
  to {
    width: 0;
    opacity: 0;
  }
}

.message-input textarea {
  max-height: 150px;
  width: calc(100% - 60px);
  flex-grow: 1;
  border: none;
  color: var(--text-color);
  background-color: transparent;
  resize: none;
  box-sizing: border-box;
  height: 54px;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
  padding: 15px 20px;
  font-family: var(--font-family);
}

.message-input textarea:focus {
  outline: none;
}

.button-container {
  position: relative;
  height: 52px;
  width: 52px;
  margin: 1px;
  flex-shrink: 0;
}

.message-input button {
  border: none;
  background: none;
  cursor: pointer;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: 52px;
  transition: opacity 0.2s, transform 0.2s;
}

.message-input button img {
  height: 50px;
  width: 50px;
  transition: filter 0.2s;
}

.message-input button img:active {
  filter: grayscale(50%);
}

.send-button {
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
}

.send-button.visible {
  animation: sendButtonExpand 0.3s forwards;
  margin-right: -5px;
}

@keyframes sendButtonExpand {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}